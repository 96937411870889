<template>
	<div id="page">
		<div class="content">
			<van-row justify="center" align="center" style="height:100%">
				<van-col span="24" style="text-align:center">
					<h1>请输入验证码</h1>
					<input class="code" v-model="code" type="password" />
					<div>
					<button class="btn" @click="submit">
						进入活动
					</button>
					</div>
				</van-col>
			</van-row>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import Common from '../library/Common';
import { get } from '../library/serviceUtil';
import '../css/font.css';
import { Toast } from 'vant';


export default {
	name: 'wall',
	components: {
	},
    setup(){
		const router = useRouter();
		const common = new Common();
		let state = reactive({
			code:"",
		});

		const submit = async()=>{
			try{
				var res = await get(`/activity/program/from-code/?magic_no=${state.code}`);
				if(res.status == 200){
					common.setLocal("activity",res.data);
					if(res.data.blessing.has){
						router.push({name:"blessing"});
					}else if(res.data.voting.has){
						router.push({name:"ranking"});
					}else if(res.data.lottery.has){
						router.push({name:"luckDraw"});
					}else{
						Toast("活动没有任何节目");
					}
				}
			}catch(error){
				if(error.response.status == 404){
                    Toast("验证码错误");
                }else{
					Toast(`其他错误 ${error.response.status}`);
				}
			}
		}
		return {
			...toRefs(state),
			submit,
		};
	}
}
</script>
<style scoped>
#page{
	width: 100%;
	height: 100%;
	background-color: #fff;
	background-image: url('../assets/bg.png');
	background-repeat: no-repeat;
    background-size: 100% 100%;
	cursor:default;
}
.content{
	width: 100%;
	height: 100%;
	border:1px solid #000;
}
h1{
	color:#fff;
}
.code{
	padding:5px 10px;
	border-radius: 10px;
	font-weight: 600;
	text-align: center;
}

.btn{
	width:1rem;
	background-color: #1989FA;
	font-weight: 600;
	font-size:0.1rem;
	color:#fff;
	margin:10px auto;
	padding:5px 10px;
	border-radius:10px;
}
</style>